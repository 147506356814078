import { Card,Row,Col,Form,Select, Button, Table } from "antd";
import { useEffect, useState } from "react";
import { BusinessNoDropDownDto,BusinessNumberService,BargeService,BusinessNoPlantCodeReq, BNoRequest, ReportsService, CostingReportsReqDto } from "@exportx/shared-models-and-services";
import { AlertMessages, useAuthState } from "../../../common";
import { SequenceUtils } from "@exportx/ui-utils";
import moment from "moment";
import {StatusTag} from "../../../common";

const {Option} = Select;
export const CostingReports = () => {
    const { authContext } = useAuthState();
    const [formRef] = Form.useForm();
    const [businessDropDown, setBusinessDropDown] = useState<BusinessNoDropDownDto[]>([]);
    const [dropDown, setDropDown] = useState<any[]>([]);
    const [data,setData] = useState<any[]>([]);



    const businessService = new BusinessNumberService();
    const bargeService = new BargeService();
    const reportsService = new ReportsService();
    
    useEffect(()=>{
        getBusinessNoDropDown()
        getBargeDropForBusinessNo(undefined);
    },[])
    const getBusinessNoDropDown = () => {
        const req = new BusinessNoPlantCodeReq(authContext.defaultPlant)
        businessService.getBusinessNoDropDown(req).then(res => {
          if (res.status) {
            setBusinessDropDown(res.data)
          } else {
            console.log(res.internalMessage)
          }
        }).catch(err => {
          console.log(err.message)
        })
    }
    const getBargeDropForBusinessNo = (businessNo?: string) => {
        formRef.setFieldsValue({ barges: null });
        setDropDown([]);
        const req = new BNoRequest(businessNo);
        req.plantCode = authContext.defaultPlant;
        // req.businessNo = businessNo;
        bargeService.getBargeDropForBusinessNo(req).then(res => {
          if (res.status) {
            const mappedData = res.data.map((bgData) => ({
              bgId: bgData.bargeId,
              Id: bgData.id,
              plannedQuantity: bgData.bargeQuantityInMt,
              bargingBasePrice: bgData.chemicalSprayPrice,
              chemicalSprayCurrency: bgData.chemicalSprayCurrency,
              bargeNomination: bgData.bargeNomination,
            }));
            setDropDown(mappedData);
          }
        }).catch(err => {
          console.log(err.message)
        })
      }

      const submitHandler = () => {
          let values = formRef.getFieldsValue(['barges','businessNo']);
          const req = new CostingReportsReqDto(values.barges,values.businessNo,authContext.defaultPlant)
          if(!req.bargeId && !req.businessNo){
              return AlertMessages.getErrorMessage('Please Select business No or barge or Both');
          }
            reportsService.getCostingDetails(req).then(res => {
            if (res.status) {
              setData(res.data);
              AlertMessages.getSuccessMessage(res.internalMessage);
              
            } else {
              setData([])
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }).catch(err => {
            // console.log('error: ' + err.message);
            AlertMessages.getErrorMessage(err.message);
          });
      }

      console.log('dropdown',dropDown)
     
      
      const columns: any = [
        {
            title: 'Costing No',
            dataIndex: 'costingNo',
            align: 'center',
        },
        {
            title: 'Costing Type',
            dataIndex: 'costingType',
            align: 'center'
        },
        {
            title: 'Costing Date',
            dataIndex: 'costingDate',
            align: 'center',
            render: (text: string) => moment(text).format('DD/MM/YYYY')
        },
        {
            title: 'Business Partner',
            dataIndex: 'bpName',
            align: 'center'
        },
        {
          title: 'Business No',
          dataIndex: 'businessNo',
          // sorter: (a, b) => a.businessNumber.localeCompare(b.businessNumber),
          // sortDirections: ['descend', 'ascend'],
          render: (value, record) => {
              if (!value) {
                return null;
              }
              const link = `/#/bn-detail-view?bn_Id=${record.bnId}`
              return <>
                  <a href={link}>{value} ({record.Mvn})</a>
              </>
          }
        },
        {
          title: 'Barge',
          dataIndex: 'bargeId',
          render: (v, record) => {
            if (!record.bargeData) {
                return " ";
            }
            const bargeEntries = record.bargeData.split(',');
            return (
                <>
                  {bargeEntries.map((barges, index) => {
                      const [bargeInfo, bgId] = barges.split('~^');
                      const bargeId = bargeInfo.match(/\d+/)[0];
                      const bargeName = bargeInfo.match(/\((.*?)\)/)[1];
                      const link = `/#/barge-detail-view?barge_id=${bgId}`;
                        return (
                          <tr key={index}>
                              <a href={link} className="link-primary">
                                  {SequenceUtils.formatNumberToSpecificLength(bargeId) + " " + `(${bargeName})`}
                              </a>
                          </tr>
                        );
                    })}
                </>
            );
          }
        },
        {
            title: 'Amount',
            dataIndex: 'total',
            align: 'center',
        },
        {
          title: 'Currency',
          dataIndex: 'currency',
          align: 'center'
        },
        {
          title: 'Status',
          dataIndex: 'approvalStatus',
          onCell: (record: any) => ({
              rowSpan: record.rowSpan
          }),
          render: (value, _) => {
              return <>
                  <StatusTag status={value}></StatusTag>
              </>
          }
      },
      ]
    
    return (
      <Card
        title={<span style={{ color: 'white' }}>Costing Reports</span>}
        style={{ textAlign: 'left' }}
        headStyle={{ backgroundColor: '#2196f3', border: 0 }}
      >
        <Form form={formRef} autoComplete="off" layout="vertical">
          <Row gutter={16}>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                label="Business Number"
                rules={[{ required: false }]}
                name="businessNo"
              >
                <Select
                  onChange={(value) => getBargeDropForBusinessNo(value)}
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toString()
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  allowClear
                  showSearch
                  placeholder="Select Business Number"
                >
                  {businessDropDown.map((bn) => {
                    return (
                      <Option value={bn.bnId}>
                        {bn.businessName}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                label="Barge"
                rules={[{ required: false }]}
                name="barges"
              >
                <Select
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toString()
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  allowClear
                  showSearch
                  placeholder="Select Barge"
                >
                   {dropDown.map((rec) => {
                    return (
                      <Option value={rec.Id}>
                        {SequenceUtils.formatNumberToSpecificLength(rec.bgId.toString()) + " " + `(${rec.bargeNomination})`}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col>
                <Button type="primary" htmlType="submit" style={{ marginTop: '24px' }} onClick={submitHandler}>
                Submit
                </Button>
            </Col>
                      <Table
                        className='contracts'
                        bordered
                        columns={columns}
                        dataSource={data}
                        // pagination={false}
                        style={{width: '100%'}}
                      />;
            <br />
          </Row>
        </Form>
      </Card>
    );
}

export default CostingReports;